import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <h1 className="text-4xl font-semibold text-center pb-10 px-10">Privacy Policy</h1>
    <div className="flex justify-center px-10">
      <p className="max-w-xl">
        By creating an account with Simmr, you agree to having your email address
        stored in Google‘s firebase authentication service. Your email address
        will be used to verify your account and reset your password. Your email
        account will also be utilized in communications, to make you aware of any
        policy updates and added features.
      </p>
    </div>
  </Layout>
)

export default PrivacyPage
